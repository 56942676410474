<template>
  <div class="subsidiray">
    <banner :bannerIndex="1" />
    <ul class="content_w subNav_box animate__animated animate__fadeInUp">
       <li v-for="(item,index) in companyList" :key="index" :class="navActive == index? 'subNav_bg' : ''" @click="subNavFn(index)">{{item.title}}</li>
    </ul>
    <div class="content_w" v-if="fieldShow">
      <ul class="subcont_box" v-for="(item,index) in companyList" :key="index" v-show="navActive == index">
          <li v-for="(itema,indexa) in item.detail" :key="indexa" >
            <div class="subtu_tit animate__animated animate__fadeInLeft" >
              <div class="subtu_txt">
                <span></span>
                {{itema.title}}
              </div>
              <div class="subtu_p">
                <span>{{itema.describe}}</span>
              </div>
              <!-- @click="subNavFn(index)" -->
               <div class="modular2_bt ny_more list_more" @click="goSub(itema.id)">
                  <div class="xian"></div>
                  <span>查看详情</span>
                  <div class="yuan"></div>
                  <img src="../../static/img/rjt2.png" class="more_img" />
                  <img src="../../static/img/rjt3.png" class="more_img1" />
                </div>
            </div>
            <div class="subtu animation_img animate__animated animate__fadeInRight">
              <img :src="itema.pic"/>
            </div>
          </li>
      </ul>
      <div class="top_bt" @click="goTop">
        <img src="../../static/img/top.png"/>
        TOP
      </div>
    </div>
    <div v-if="!fieldShow" class="height2r"></div>
  </div>
</template>

<script>
import banner from "../components/home/banner.vue";
export default {
  name: "subsidiray",
   components: {
    banner
  },
  data() {
    return {
      tabIndex: 0,
      fieldShow:false,
      inwidth: window.innerWidth,
      companyList:[],
      navActive:this.$route.query.navctiveIndex || 0,
      navList:['业务主体子公司','供应链服务公司','产业链延伸实体公司','境外贸易平台公司'],
      subList:[{
        tit:'杭州宜邦橡胶有限公司',
        img:require('../../static/img/subtu1.jpg'),
        cont:['成立于2018年3月，注册资本5000万元人民币，占地总面积11万平方米，员工160余人，是浙江省内第一家丁苯橡胶生产企业，填补了省内丁苯橡胶产品行业的空白。丁苯橡胶是最大的通用合成橡胶品种，也是最早实现工业化生产的橡胶之一，具有生产工艺技术成熟、性能较天然橡胶更为优良等特点。宜邦橡胶产品主要为中策橡胶、三角轮胎、韩泰轮胎等国内知名轮胎企业配套供货。']
      },{
        tit:'杭实善成泰国实业有限公司',
        img:require('../../static/img/subtu2.jpg'),
        cont:['成立于2019年10月，注册资本1亿泰铢，办公地设在泰国橡胶主产区之一的素叻他尼府素叻他尼市，紧邻橡胶工业群和胶园，主营业务为天然橡胶贸易。泰国公司的成立是杭实国贸向橡胶产业链上游拓展的重要一环，未来将基于泰国公司据点深入产业链上游端，加强对上游端的开发和研究，为开展东南亚及国际橡胶业务打下良好基础。']
      },{
        tit:'内蒙古敕勒川糖业有限责任公司',
        img:require('../../static/img/subtu3.jpg'),
        cont:['成内蒙古敕勒川糖业有限责任公司成立于 2017 年，注册资本 2.67亿元，主要从事甜菜种植、白砂糖及糖蜜、甜菜粕等系列产品的加工与销售，通过了 ISO9001、ISO22000、FSSC22000、欧盟有机、中绿华夏有机等多项认证，18/19 产出糖质量评比名列全国前茅。','杭实国贸投资内蒙古敕勒川糖业有限责任公司，将深入白糖产业，发挥其原料成本和生产管理优势，推动产业资源整合，实现共同发展。']
      }],
    };
  },
  watch: {
    $route(to, from) {
      console.log('to',to)
      if(to.query.navctiveIndex){
          this.navActive = to.query.navctiveIndex;
      }
       
    }
  },
  beforeRouteUpdate (to, from, next) {
    console.log('to',to)
    this.navActive = to.params.id
    next()
  },
  mounted() {
    // this.getData()
    this.company_list();
    window.addEventListener("scroll", this.onScroll,true);
    if(this.inwidth>768){
      setTimeout((res) => {
        this.fieldShow = true;
      }, 2500);
    }else{
      this.fieldShow = true;
    }
     
  },
 
  methods: {
    goSub(subid){
      this.$router.push({
        path: "/subsidiaryCont?subid="+subid,
      });
    },
    onScroll(){
				let inHeight = window.innerHeight
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
			if(scrollTop>0){
        this.fieldShow = true;
      }
		},
     company_list() {
			this.$api.company_list({}).then(res => {
				if (res.status == 1) {
          this.companyList = res.data;
				} else {
          layer.msg(res.msg)
				}
			});
    },
    goTop(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0; 
    },
    getData() {
      var that = this;
      let data = { id: that.newsid };
      that.$api.newsDetail(data).then((res) => {
        if (res.status == 1) {
         
        } else {
          that.$layer.msg(res.msg);
        }
      });
    },
    subNavFn(index){
      this.navActive = index
    }
  },
  beforeDestroy() {
    //清除监听
    let that=this
    window.removeEventListener("scroll", that.onScroll, true);
  }
};
</script>

<style scoped>
.subNav_box{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -1.2rem;
  z-index: 1000;
}
.subNav_box li{
  flex: 1;
  margin-left: .75rem;
  background: rgba(255, 255, 255, .9);
  height: .8rem;
  line-height: .8rem;
  text-align: center;
  font-size: .24rem;
  color: #333;
  cursor: pointer;
  transition:background .5s ease-in-out ;
  padding: 0 .1rem;
}
.subNav_box li:first-child{
  margin-left:0;
}
.subNav_box li:hover{
  background: rgba(162, 16, 3, .85);
  color: #fff;
}
.subNav_box .subNav_bg{
  background: rgba(162, 16, 3, .85);
  color: #fff;
}
.subcont_box {
  margin-top: 1.2rem;
}
.subcont_box li{
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: .6rem;
}
.subcont_box li:nth-child(odd){
    justify-content: flex-end;
}
.subcont_box li .subtu_tit{
  position:absolute;
  font-size: .16rem;
  width: 6rem;
  min-height: 3.6rem;
  background: #fff;
  padding: .5rem;
  top: .65rem;
  z-index: 99;
  box-shadow: -.02rem .02rem  .1rem .1rem rgba(0,0,0,.02);
}
.subcont_box li:nth-child(odd) .subtu_tit{
   right: 7rem;
}
.subcont_box li:nth-child(even) .subtu_tit{
   left: 7rem;
}
.subtu{
  width: 7.9rem;
  height: 5.9rem;
}
.subtu img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.subtu_txt span{
  display: block;
  width: .4rem;
  height: .03rem;
  background: #a21003;
  margin-bottom: .2rem;
  font-weight: bold;
}
.subtu_txt{
  font-size: .3rem;
}
.subtu_p{
  color: #666666;
  line-height: .36rem;
  margin-top: .5rem;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.top_bt{
  width: 1rem;
  height: 1rem;
  background: #a21003;
  font-size: .18rem;
  color:#fff;
  text-align: center;
  font-weight: bold;
  margin: .7rem auto;
  transition:background .5s ease-in-out;
  cursor: pointer;
}
.top_bt:hover{
  background:#e60012;
}
.top_bt img{
  display: block;
  width: .4rem;
  height: .35rem;
  margin: 0 auto;
  padding-top: .2rem;
  margin-bottom: .05rem;
}
@media (max-width: 1680px) {
  .subcont_box li:nth-child(odd) .subtu_tit{
    right: 50%;
  }
  .subcont_box li:nth-child(even) .subtu_tit{
    left: 50%;
  }
  .subcont_box li .subtu_tit{
    width: calc(50% - 1rem);
  }
}
@media (max-width: 1560px) {
  .subNav_box li{
    margin-left: .4rem;
  }
}

@media (max-width: 1200px) {
  .subNav_box li{
    margin-left: .2rem;
    font-size: .2rem;
  }
  .subtu{
      width: 70%;
      height: 4.9rem;
  }
}
@media (max-width: 768px) {
  .subNav_box li{
    margin-left: .2rem;
    font-size: .2rem;
  }
  .subtu{
      width: 100%;
      height: 4.9rem;
      margin-top: 1rem;
  }
  .subcont_box li{
    display: block;
  }
  .subcont_box li .subtu_tit{
    position: relative;
    width: auto;
  }
  .subcont_box li:nth-child(odd) .subtu_tit{
    right: 0;
  }
  .subcont_box li:nth-child(even) .subtu_tit{
    left: 0;
  }
  .subcont_box li .subtu_tit{
    box-shadow: none;
    min-height:auto;
    padding: 0;
  }
  .subtu_txt{
    font-size: 18px;
  }
  .subtu_p{
    font-size: 12px;
    line-height: 24px;
  }
  .subNav_box{
    display: none;
  }
  .subcont_box{
    margin-top: .5rem;
  }
}

</style>
